// keep polyfills at the beginning
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "resize-observer-polyfill/dist/ResizeObserver.global";

import at from "array.prototype.at";
at.shim();

import "@kraaft/shared/core/utils/debug/wdyr"; // <--- first import

import React from "react";
import ReactDOM from "react-dom/client";
import { noop } from "ts-essentials";

import { i18n } from "@kraaft/shared/core/services/i18next";
import { setMomentLocale } from "@kraaft/shared/core/services/i18next/momentLocale";
import { isIE } from "@kraaft/shared/core/utils";
import { IEBanner } from "@kraaft/web/src/views/app/ieBanner";

setMomentLocale(i18n.language);

const isDev = __DEV__;
(window as any).__DEV__ = __DEV__;

// TODO: Find a better way to remove logs.
if (!isDev) {
  console.debug = noop;
  console.info = noop;
  console.log = noop;
  console.warn = noop;
}

const run = async () => {
  let MainContainer: React.ElementType;

  if (isIE()) {
    MainContainer = IEBanner;
  } else {
    const App = (await import("@kraaft/web/src/views/app/app")).App;

    MainContainer = App;
  }

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  );
  root.render(<MainContainer />);
};

void run();
