import { ButtonHTMLAttributes, CSSProperties, MutableRefObject } from "react";

import { KeyOfEnum } from "@kraaft/helper-types";
import { IconName, IconSize } from "@kraaft/ui";

import { AccessibilityLabel } from "../../types";

type TooltipPlacement =
  | "top"
  | "bottom"
  | "right"
  | "left"
  | "bottom-end"
  | "bottom-start"
  | "left-end"
  | "left-start"
  | "right-end"
  | "right-start"
  | "top-end"
  | "top-start";

export const ButtonType = {
  PRIMARY: "Primary",
  SECONDARY: "Secondary",
  TERTIARY: "Tertiary",
  QUATERNARY: "Quaternary",
  MAP: "Map",
} as const;

export type ButtonType = KeyOfEnum<typeof ButtonType>;

export const ButtonSize = {
  NORMAL: "Normal",
  SMALL: "Small",
} as const;

export type ButtonSize = KeyOfEnum<typeof ButtonSize>;

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type ButtonProps = RequireAtLeastOne<
  {
    id?: string;
    text?: string;
    tooltip?: string;
    tooltipPlacement?: TooltipPlacement;
    accessibilityLabel?: AccessibilityLabel;
    icon?: IconName;
    // IMO it would be better to just any component as icon, so callsite could customize the icon itself
    iconSize?: IconSize;
    contentColor?: string;
    variant?: ButtonType;
    destructive?: boolean;
    size?: ButtonSize;
    alignContent?: "left" | "center";
    width?: CSSProperties["width"];
    // Function handlers
    onPress?: () => void;
    onPressIn?: () => void;
    // State
    selected?: boolean;
    disabled?: boolean;
    loading?: boolean;
    autoFocus?: boolean;
    condensed?: boolean;
    style?: React.CSSProperties;
    disableAccessibility?: boolean;
    type?: ButtonHTMLAttributes<React.SyntheticEvent>["type"];
    testID?: string;
    ref?: MutableRefObject<null>;
  },
  "text" | "tooltip" | "accessibilityLabel"
>;
