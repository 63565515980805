import { makeStyles } from "@mui/styles";

import { FontSize, Spacing } from "@kraaft/ui";

import { baseStyle, generateButtonStyle } from "./button.styles.common";

export const useStyles = makeStyles({
  tooltip: {
    padding: Spacing.S8,
    fontSize: FontSize.SMALL,
  },
  root: {
    display: "inline-flex" as const,
    border: 0,
    outline: 0,
    cursor: "pointer" as const,
    position: "relative" as const,
    userSelect: "none" as const,
    textAlignVertical: "center" as const,
    textDecoration: "none" as const,
    ...baseStyle.button,
    ...baseStyle.textNormal,
  },
  sizeNormal: baseStyle.sizeNormal,
  sizeSmall: baseStyle.sizeSmall,
  condensedNormal: baseStyle.condensedNormal,
  condensedSmall: baseStyle.condensedSmall,
  Primary: {
    ...generateButtonStyle({ variant: "PRIMARY" }),
    "&:hover": generateButtonStyle({
      variant: "PRIMARY",
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "PRIMARY",
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "PRIMARY",
      disabled: true,
    }),
  },
  destructivePrimary: {
    ...generateButtonStyle({ variant: "PRIMARY", destructive: true }),
    "&:hover": generateButtonStyle({
      variant: "PRIMARY",
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "PRIMARY",
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "PRIMARY",
      destructive: true,
      disabled: true,
    }),
  },
  Secondary: {
    ...generateButtonStyle({ variant: "SECONDARY" }),
    "&:hover": generateButtonStyle({
      variant: "SECONDARY",
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "SECONDARY",
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "SECONDARY",
      disabled: true,
    }),
  },
  destructiveSecondary: {
    ...generateButtonStyle({
      variant: "SECONDARY",
      destructive: true,
    }),
    "&:hover": generateButtonStyle({
      variant: "SECONDARY",
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "SECONDARY",
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "SECONDARY",
      destructive: true,
      disabled: true,
    }),
  },
  Tertiary: {
    ...generateButtonStyle({ variant: "TERTIARY" }),
    "&:hover": generateButtonStyle({
      variant: "TERTIARY",
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "TERTIARY",
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "TERTIARY",
      disabled: true,
    }),
  },
  destructiveTertiary: {
    ...generateButtonStyle({
      variant: "TERTIARY",
      destructive: true,
    }),
    "&:hover": generateButtonStyle({
      variant: "TERTIARY",
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "TERTIARY",
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "TERTIARY",
      destructive: true,
      disabled: true,
    }),
  },
  Quaternary: {
    ...baseStyle.quarternaryPaddings,
    ...generateButtonStyle({ variant: "QUATERNARY" }),
    "&:hover": generateButtonStyle({
      variant: "QUATERNARY",
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "QUATERNARY",
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "QUATERNARY",
      disabled: true,
    }),
  },
  destructiveQuaternary: {
    ...baseStyle.quarternaryPaddings,
    ...generateButtonStyle({
      variant: "QUATERNARY",
      destructive: true,
    }),
    "&:hover": generateButtonStyle({
      variant: "QUATERNARY",
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "QUATERNARY",
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "QUATERNARY",
      destructive: true,
      disabled: true,
    }),
  },
  Map: {
    ...generateButtonStyle({ variant: "MAP" }),
    "&:hover": generateButtonStyle({ variant: "MAP", hovered: true }),
    "&:active": generateButtonStyle({
      variant: "MAP",
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "MAP",
      disabled: true,
    }),
  },
  destructiveMap: {
    ...generateButtonStyle({ variant: "MAP", destructive: true }),
    "&:hover": generateButtonStyle({
      variant: "MAP",
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "MAP",
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "MAP",
      destructive: true,
      disabled: true,
    }),
  },

  // selected & alignLeft must be defined after other styles to take CSS precedence
  selected: {
    ...generateButtonStyle({ variant: "PRIMARY", selected: true }),
    "&:hover": generateButtonStyle({
      variant: "PRIMARY",
      selected: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: "PRIMARY",
      selected: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: "PRIMARY",
      selected: true,
      disabled: true,
    }),
  },
  alignLeft: baseStyle.buttonAlignLeft,
});
